/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Cancel,
  Edit,
  LinkRounded,
  Menu as MenuIcon,
} from "@mui/icons-material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import PropTypes from "prop-types";

import { setError } from "@redux/slices/errorSlice";

import { axiosPost } from "../../api/axiosCalls";

const MaintenanceMenu = ({
  currentOrder,
  handleCancelOpen,
  handleEditOpen,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const { orderSetId } = useSelector(
    (state) => state.orderHistory.currentOrder
  );

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResendConfirmationEmail = async () => {
    const res = await axiosPost(
      `/api/orders/${currentOrder.id}/resend-order-confirmation`,
      { body: {} }
    );
    if (res.error) {
      console.error(res.error);
      dispatch(
        setError({ error: res.error, source: "Resend email confirmation" })
      );
    } else {
      setEmailSent(true);
    }
    setEmailLoading(false);
  };

  return (
    <>
      <Tooltip title="Order Maintenance">
        <IconButton
          aria-owns={anchorEl ? "order-maintenance" : undefined}
          aria-haspopup="true"
          onClick={(evt) => {
            handleOpen(evt);
          }}
          size="large"
        >
          <MenuIcon fontSize="large" color="inherit" />
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={`/order-sets/${orderSetId}/overview`}
          target="_blank"
        >
          <ListItemIcon>
            <LinkRounded color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Go To Order Set" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleEditOpen("order");
            handleClose();
          }}
        >
          <ListItemIcon>
            <Edit color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Order Level Address" />
        </MenuItem>
        <MenuItem
          disabled={emailLoading}
          onClick={handleResendConfirmationEmail}
        >
          <ListItemIcon>
            <SendRoundedIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Resend Order Confirmation"
            secondary={emailSent && "Email was sent!"}
          />
          {emailLoading && <CircularProgress size={16} tw="ml-4" />}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleCancelOpen("order");
            handleClose();
          }}
        >
          <ListItemIcon>
            <Cancel color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Cancel Entire Order" />
        </MenuItem>
      </Menu>
    </>
  );
};

MaintenanceMenu.propTypes = {
  handleCancelOpen: PropTypes.func.isRequired,
  handleEditOpen: PropTypes.func.isRequired,
};

export default MaintenanceMenu;
