import { format } from "date-fns";

import {
  formatDateString,
  formatMoney,
  formatMoneyString,
  stringToCents,
  upCase,
  utcDate,
} from "@utility/utilityFunctions";

import csvToJson from "./csvToJson";

const handleGroups = (categories) => {
  return categories.reduce((acc, c) => {
    let categoryGroups = c.groups.map((g) => ({
      id: g.id,
      categoryId: c.id,
      name: g.name,
    }));
    return acc.concat(categoryGroups);
  }, []);
};

export const mapOrderWindowSummary = (report, categories) => {
  if (report.length === 0) return [];
  let groups = handleGroups(categories);

  const data = report.map((row) => {
    let rowData = {
      id: row.id,
      sku: row.variant_sku,
      externalWarehouseId: row.external_warehouse_id,
      customerIdentifier: row.customer_identifier,
      cost: formatMoney(stringToCents(row.cost), false),
      totalCost: formatMoney(
        row.total_order_variants_quantity * stringToCents(row.cost),
        false
      ),
      price: formatMoney(stringToCents(row.price), false),
      totalPrice: formatMoney(
        row.total_order_variants_quantity * stringToCents(row.price),
        false
      ),
      name: row.name,
      moq: row.minimum_order_quantity,
      supplier: row.supplier_name,
      program: row.program_name,
      qty: row.total_order_variants_quantity,
      discount: formatMoney(
        stringToCents(row.total_order_variants_discount, false)
      ),
      orderWindowName: row.order_window_name,
      totalEstimatedTax: formatMoneyString(row.total_estimated_tax),
    };

    row.group_ids.forEach((id) => {
      let group = groups.find((g) => +g.id === id);
      rowData[`group-${group.categoryId}`] = group.name;
    });

    return rowData;
  });

  return data;
};

export const mapOrderWindowDetail = (report, categories, variantOptions) => {
  if (report.length === 0) return [];
  let groups = handleGroups(categories);

  const data = report.map((row) => {
    let rowData = {
      id: row.id,
      orderId: row.order_id,
      orderDate: format(utcDate(row.order_date), "MM/dd/yyyy"),
      name: row.name,
      sku: row.variant_sku,
      externalWarehouseId: row.external_warehouse_id,
      customerIdentifier: row.customer_identifier,
      accountCode: row.account_code,
      orderer: row.orderer,
      orderedByEmail: row.orderer_email,
      supplier: row.supplier_name,
      program: row.program_name,
      territory: row.territory,
      addressAttn: row.address_attn,
      addressStreetOne: row.address_street_1,
      addressStreetTwo: row.address_street_2 ?? "---",
      addressCity: row.address_city,
      addressState: row.address_state,
      addressZip: row.address_zip,
      addressCountry: row.address_country,
      qty: row.qty,
      cost: formatMoneyString(row.cost),
      totalCost: formatMoneyString(row.total_cost),
      price: formatMoneyString(row.price),
      totalPrice: formatMoneyString(row.total_price),
      discount: formatMoneyString(row.discount),
      status: upCase(row.status, "-"),
      variantOptionIds: row.variant_option_ids,
      promotions: row.promotions,
      packSize: row.pack_size,
      totalEstimatedShippingCost: formatMoneyString(
        row.total_estimated_shipping_cost
      ),
      totalEstimatedTax: formatMoneyString(row.total_estimated_tax),
      paymentType: upCase(row.payment_type),
      budgetName: row.budget_name,
      orderWindowName: row.order_window_name,
    };

    row.group_ids.forEach((id) => {
      let group = groups.find((g) => +g.id === id);
      rowData[`group-${group.categoryId}`] = group.name;
    });

    row.variant_option_ids.forEach((id) => {
      let variantOption = variantOptions.find((vo) => +vo.id === id);
      rowData[`variant-option-${variantOption.variantCategoryId}`] =
        variantOption.name;
    });

    return rowData;
  });

  return data;
};

export const mapPreOrderUserReport = (report) => {
  if (report.length === 0) return [];

  const data = report.map((row) => ({
    name: row.name,
    email: row.email,
    orderWindow: row.order_window,
    program: row.program,
    territory: row.territory,
  }));

  return data;
};

export const mapBudgetAdjustmentReport = (report) => {
  if (report.length === 0) return [];

  const data = report.map((row) => ({
    date: format(new Date(row.updated_at), "MM/dd/yyyy"),
    user: row.user_name,
    email: row.user_email,
    note: row.note,
    originalBalance: formatMoneyString(
      Number(row.total) - Number(row.adjustment)
    ),
    adjustment: formatMoneyString(row.adjustment),
    total: formatMoneyString(row.total),
  }));

  return data;
};

export const mapBudgetSummaryExportReport = (report) => {
  if (report.length === 0) return [];

  const data = report.map((row) => ({
    id: row.id,
    name: row.name,
    assignedTo: row.assigned_to,
    startDate: formatDateString(row.start_date),
    expirationDate: formatDateString(row.expiration_date),
    totalAmount: formatMoney(stringToCents(row.total_amount), false),
    spend: formatMoney(stringToCents(row.spend), false),
    balance: formatMoney(stringToCents(row.balance), false),
    status: row.is_active ? "Active" : "Inactive",
  }));

  return data;
};

export const mapInventorySnapshotReport = (report, categories) => {
  if (report.length === 0) return [];
  let groups = handleGroups(categories);

  const data = report.map((row) => {
    let rowData = {
      id: row.id,
      sku: row.variant_sku,
      customerIdentifier: row.customer_identifier,
      name: row.name,
      totalOnHand: row.total_on_hand ?? 0,
      cost: formatMoney(stringToCents(row.cost), false),
      ninetyDayQty: row.last_ninety_days ?? 0,
      yearQty: row.last_year ?? 0,
      warehouseSKU: row.warehouse_sku,
      inventoryValue: formatMoneyString(row.inventory_value),
    };

    row.group_ids.forEach((id) => {
      let group = groups.find((g) => +g.id === id);
      rowData[`group-${group.categoryId}`] = group.name;
    });

    return rowData;
  });

  return data;
};

export const mapInventoryVelocityReport = (report, categories) => {
  if (report.length === 0) return [];
  let groups = handleGroups(categories);

  const data = report.map((row) => {
    let rowData = {
      id: row.id,
      sku: row.variant_sku,
      customerIdentifier: row.customer_identifier,
      name: row.name,
      totalQty: row.total_ordered,
      monthAverage: row.avg,
      leadTime: row.lead_time ?? "0",
      reorderQty: row.reorder,
      reorderThreshold: +row.reorder_threshold,
      onHandQty: row.total_on_hand,
      difference: row.difference,
      weeksOfInventory: row.weeks_of_inventory,
      externalWarehouseId: row.external_warehouse_id,
      cost: formatMoneyString(row.cost),
      inventoryValue: formatMoneyString(row.inventory_value),
      warehouseSKU: row.external_warehouse_id ?? row.variant_sku,
      packSize: row.pack_size,
    };

    row.month_qtys.forEach((mq) => {
      let monthKey = Object.keys(mq)[0];
      let monthQty = mq[monthKey];
      rowData[monthKey] = monthQty;
    });

    row.group_ids.forEach((id) => {
      let group = groups.find((g) => +g.id === id);
      rowData[`group-${group.categoryId}`] = group.name;
    });

    return rowData;
  });

  return data;
};

export const mapPromoCodeReport = (report) => {
  if (report.length === 0) return [];

  const data = report.map((row) => ({
    promotion: row.promotion,
    orderSetCount: row.order_set_count,
    orderCount: row.order_count,
    discounts: formatMoney(stringToCents(row.discounts), false),
    totalSales: formatMoney(stringToCents(row.total_sales), false),
    totalSalesAfterDiscount: formatMoney(
      stringToCents(row.total_sales_after_discount),
      false
    ),
  }));

  return data;
};

export const mapVariantAllocationReport = (report) => {
  if (report.length === 0) return [];

  const getAvailable = (row) => {
    let total = row.allocated - row.ordered - row.pending;

    if (total >= 0) return total;
    return 0;
  };

  const getUnallocatedOrderQty = (row) => {
    let total = row.allocated - row.ordered - row.pending;

    if (total < 0) return total * -1;
    return 0;
  };

  const data = report.map((row) => ({
    warehouseSku: row.external_warehouse_id ?? row.variant_sku,
    itemName: row.item_name,
    territoryName: row.territory_name,
    userName: row.user_name,
    totalAllocated: row.allocated,
    ordered: row.ordered,
    pending: row.pending,
    available: getAvailable(row),
    unallocatedOrderQty: getUnallocatedOrderQty(row),
  }));

  return data;
};

export const mapShippedOrdersReport = (report, categories) => {
  if (report.length === 0) return [];
  let groups = handleGroups(categories);

  const data = report.map((row) => {
    let rowData = {
      id: row.id,
      orderId: row.order_id,
      orderedBy: row.ordered_by,
      sku: row.variant_sku,
      customerIdentifier: row.customer_identifier,
      orderedDate: format(new Date(row.ordered_date), "MM/dd/yyyy"),
      shippedDate: formatDateString(row.shipped_date),
      territory: row.territory,
      itemName: row.item_name,
      addressName: row.address_name,
      addressStreetOne: row.address_street_1,
      addressStreetTwo: row.address_street_2,
      addressCity: row.address_city,
      addressState: row.address_state,
      addressZip: row.address_zip,
      addressCountry: row.address_country,
      qty: row.qty,
      price: formatMoney(stringToCents(row.price), false),
      totalPrice: formatMoney(stringToCents(row.total_price), false),
      estShippingCost: formatMoney(
        stringToCents(row.estimated_shipping_cost),
        false
      ),
      orderType: upCase(row.order_type, "-"),
      budgetName: row.budget_name,
      checkoutType: upCase(row.checkout_type),
      orderedByEmail: row.ordered_by_email,
      accountCode: row.account_code,
      carrier: upCase(row.carrier),
      tracking: row.tracking,
      expeditedDate: formatDateString(row.expedited_date),
      warehouseSKU: row.warehouse_sku,
      estimatedTax: formatMoneyString(row.estimated_tax),
      actualFreight: formatMoneyString(row.actual_freight),
      actualTax: formatMoneyString(row.actual_tax),
      approvedDate: format(new Date(row.approved_date), "MM/dd/yyyy"),
      isForEvent: row.is_for_event ? "Yes" : "No",
    };

    row.group_ids.forEach((id) => {
      let group = groups.find((g) => +g.id === id);
      rowData[`group-${group.categoryId}`] = group.name;
    });

    return rowData;
  });

  return data;
};

export const mapProductListReport = (report, categories) => {
  if (report.length === 0) return [];
  let groups = handleGroups(categories);
  const data = report.map((row) => {
    let rowData = {
      id: row.id,
      sku: row.sku,
      customerIdentifier: row.customer_identifier,
      name: row.name,
      description: row.description ?? "---",
      visibility:
        row.availability === "All" ? "All Territories" : row.item_territories,
      unitOfMeasure: row.unit_of_measure,
      packSize: row.pack_size,
      cost: formatMoney(stringToCents(row.cost), false),
      price: formatMoney(stringToCents(row.price), false),
      orderType: row.order_type ? upCase(row.order_type, "-") : "---",
      onHand: row.on_hand_inventory ?? "---",
      status: upCase(row.status),
      orderableStartDate: formatDateString(row.orderable_start_date),
      orderableEndDate: formatDateString(row.orderable_end_date),
      visibleStartDate: formatDateString(row.visible_start_date),
      visibleEndDate: formatDateString(row.visible_end_date),
      reorderThreshold: +row.reorder_threshold,
      supplier: row.supplier_name,
      externalWarehouseId: row.external_warehouse_id ?? row.sku,
    };

    row.group_ids.forEach((id) => {
      let group = groups.find((g) => +g.id === id);
      rowData[`group-${group.categoryId}`] = group.name;
    });

    return rowData;
  });

  return data;
};

export const mapAllocationSnapshotReport = (report) => {
  if (report.length === 0) return [];

  const data = report.map((row) => {
    let rowData = {
      currAllocatedInventory: row.curr_allocated_inventory,
      currOnHandInventory: row.curr_on_hand_inventory,
      currUnallocatedInventory: row.curr_unallocated_inventory,
      groupName: row.group_name,
      itemName: row.item_name,
      packSize: row.pack_size,
      territories: row.territories,
      variantId: row.variant_id,
      variantSku: row.variant_sku,
    };

    return rowData;
  });

  return data;
};

export const mapBeaconLocationReport = (report) => {
  if (!report || report.length === 0) return [];

  const data = csvToJson(report);
  return data.map((row) => ({
    beaconInstanceId: row["Beacon Instance ID"],
    brandhubPo: row["brandhub PO #"],
    itemSku: row["Item SKU"],
    itemName: row["Item Name"],
    vpid: row["VPID#"] || "No Match",
    locationName: row["Location Name"] || "No Match",
    streetAddress1: row["Street Address 1"] || "No Match",
    streetAddress2:
      row["Street Address 2"] || row["Street Address 1"] ? "" : "No Match",
    city: row["City"] || "No Match",
    state: row["State"] || "No Match",
    zip: row["Zip"] || "No Match",
    firstPing: format(new Date(row["First Ping"]), "MM/dd/yyyy HH:mm"),
    lastPing: format(new Date(row["Last Ping"]), "MM/dd/yyyy HH:mm"),
    daysSinceFirstPing: row["Days Since First Ping"],
    daysSinceLastPing: row["Days Since Last Ping"],
    totalPings: row["Total # of Pings"],
  }));
};
