/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Contained } from "src/components/StyledComponents";

import OrderSetTutorial from "@components/tutorial/OrderSetTutorial";
import {
  OrderSetActionBar,
  OrderSetNavigation,
  OrderSetTable,
  useCurrentOrderSet,
} from "@features/ordering";
import {
  updateCurrentChannel,
  updateCurrentTerritory,
} from "@redux/slices/user/currentUserSlice";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

const OrderSet = () => {
  const dispatch = useDispatch();
  const { orderSet } = useCurrentOrderSet();
  const canViewOnceSubmitted = useRoleIs()(permissions.orderReview);
  const { currentChannel, currentTerritory, hasViewedOrderSet } = useSelector(
    (state: any) => state.currentUser
  );

  useEffect(() => {
    if (orderSet.channel && orderSet.channel?.id !== currentChannel) {
      dispatch(updateCurrentChannel({ channel: orderSet.channel.id }));
    }
    if (orderSet.territory.id !== currentTerritory) {
      dispatch(updateCurrentTerritory({ territory: orderSet.territory.id }));
    }
  }, [
    dispatch,
    currentChannel,
    currentTerritory,
    orderSet.territory.id,
    orderSet.channel,
  ]);

  if (
    (orderSet.status !== "draft" && !canViewOnceSubmitted) ||
    orderSet.status === "approved"
  )
    return <Navigate to="./overview" replace />;

  return (
    <div tw="min-h-[calc(100vh - var(--header-height))]">
      {!hasViewedOrderSet && <OrderSetTutorial />}
      <Contained tw="space-y-6">
        <OrderSetNavigation />
        <OrderSetTable />
        <OrderSetActionBar />
      </Contained>
    </div>
  );
};

export default OrderSet;
