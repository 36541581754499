/** @jsxImportSource @emotion/react */
// Inputs controlled by react-hook-form
import tw, { styled } from "twin.macro";

import React, { ReactElement, ReactNode, forwardRef } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";

import {
  CheckBox,
  CheckBoxOutlineBlank,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputBase,
  InputBaseProps,
  MenuItem,
  RadioGroup,
  Select,
  SelectProps,
  Switch,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";

import { addYears, format } from "date-fns";

import { utcDate } from "@utility/utilityFunctions";

import { transformEventValue } from "./helpers";

export const StyledFormLabel = styled(FormLabel)({
  "&": tw`pb-0.5 text-sm text-neutral-600`,
  "&.Mui-focused": tw`text-primary-600`,
  "&.Mui-error": tw`text-red-700`,
});

const inputStyles = {
  base: tw`w-full px-2 py-2 bg-white border border-solid rounded shadow-sm border-neutral-300 text-neutral-800`,
  focused: tw`outline outline-2 outline-primary-600 -outline-offset-2`,
  error: tw`border-red-600`,
  disabled: tw`bg-neutral-100 border-neutral-200 text-neutral-500`,
};

const StyledInput = styled(InputBase)({
  "&": inputStyles.base,
  "&.Mui-focused": inputStyles.focused,
  "&.Mui-error": inputStyles.error,
  "&.Mui-disabled": inputStyles.disabled,
  input: tw`p-0 [-webkit-text-fill-color: unset]!`,
  ".MuiAutocomplete-endAdornment": tw`right-2`,
  ".MuiAutocomplete-endAdornment .MuiIconButton-root": tw`text-neutral-400`,
});

export const StyledSelect = styled(Select)({
  ".MuiInputBase-input": inputStyles.base,
  "&.Mui-focused .MuiInputBase-input": inputStyles.focused,
  fieldset: tw`hidden`,
  ".MuiSvgIcon-root": tw`text-neutral-400 right-2`, // arrow icon
});

export type TextInputProps = InputBaseProps &
  Pick<
    TextFieldProps,
    "label" | "helperText" | "InputProps" | "InputLabelProps"
  >;

export const StyledTextInput = React.forwardRef<
  HTMLInputElement,
  TextInputProps
>(({ helperText, InputProps, InputLabelProps, className, ...props }, ref) => (
  <FormControl fullWidth className={className}>
    {props.label && (
      <StyledFormLabel error={props.error} {...InputLabelProps}>
        {props.label}
      </StyledFormLabel>
    )}
    <StyledInput ref={ref} {...props} {...InputProps} />
    {helperText && (
      <FormHelperText tw="ml-0" error={props.error}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
));

type ControlledInputBaseProps = Omit<ControllerProps, "render"> &
  InputBaseProps & {
    rules?: ControllerProps["rules"] & {
      transform?: (value: string) => string;
      transformOnBlur?: (value: string) => string;
    };
    defaultValue?: string;
  };
export const ControlledInputBase = forwardRef<
  HTMLInputElement,
  ControlledInputBaseProps
>(
  (
    { name, control, onChange, onBlur, rules, defaultValue = "", ...props },
    ref
  ) => {
    const { transform, transformOnBlur, ...registerRules } = rules || {};
    return (
      <Controller
        control={control}
        name={name}
        rules={registerRules}
        render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
          <InputBase
            inputRef={ref}
            {...field}
            {...props}
            value={field.value ?? defaultValue}
            onChange={(e) => {
              const te = transform ? transformEventValue(e, transform) : e;
              field.onChange(te);
              onChange && onChange(te);
            }}
            onBlur={(e) => {
              const te = transformOnBlur
                ? transformEventValue(e, transformOnBlur)
                : e;
              transformOnBlur && field.onChange(te);
              onBlur && onBlur(te);
              field.onBlur();
            }}
            error={!!error}
            // helperText={error?.message || props.helperText}
          />
        )}
      />
    );
  }
);

export type InputOption = {
  id: any;
  name: string;
  disabled?: boolean;
  [key: string]: any;
};

const defaultOptions = [{ id: "", name: "" }];

export type ControlledSelectInputProps = Omit<ControllerProps<any>, "render"> &
  SelectProps & {
    options: InputOption[];
    getOptionDisabled?: (option: InputOption) => boolean;
  };
export const ControlledSelectInput = ({
  control,
  name,
  options,
  label,
  className,
  rules,
  disabled,
  onChange,
  getOptionDisabled,
  ...props
}: ControlledSelectInputProps) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        className={className}
        {...{ error: !!error, disabled }}
        size="small"
        fullWidth
      >
        <StyledFormLabel id={name}>{label}</StyledFormLabel>
        <StyledSelect
          className={className}
          IconComponent={KeyboardArrowDownRounded}
          {...field}
          {...props}
          onChange={(...args) => {
            field.onChange(...args);
            onChange?.(...args);
          }}
          MenuProps={{
            ...props.MenuProps,
            PaperProps: { sx: { maxHeight: 400 } },
          }}
        >
          {(options?.length ? options : defaultOptions).map((o, i) => (
            <MenuItem
              key={`${o.id}-${i}`}
              value={o.id}
              disabled={o.disabled || getOptionDisabled?.(o)}
            >
              {o.name}
            </MenuItem>
          ))}
        </StyledSelect>
        {error && <FormHelperText>{error?.message}</FormHelperText>}
      </FormControl>
    )}
  />
);

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type ControlledAutocompleteInputProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean,
> = Omit<ControllerProps, "render"> &
  WithOptional<
    AutocompleteProps<
      InputOption,
      Multiple,
      DisableClearable,
      undefined,
      "div"
    >,
    "renderInput"
  > & {
    label?: string;
    onChange?: (
      optionId: Multiple extends true ? InputOption[] : string
    ) => void;
  };
export const ControlledAutocompleteInput = <
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>({
  control,
  name,
  options,
  label,
  rules,
  onChange,
  ...props
}: ControlledAutocompleteInputProps<Multiple, DisableClearable>) => {
  // Create a lookup for faster fetching
  const optionsLookup = options.reduce((obj, a) => {
    obj[a.id] = a;
    return obj;
  }, {});

  const getOpObj = (option) => {
    if (!option.id) option = optionsLookup[option];
    return option;
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          size="small"
          popupIcon={<KeyboardArrowDownRounded />}
          isOptionEqualToValue={(option, value) =>
            option?.id === getOpObj(value)?.id
          }
          getOptionLabel={(option) =>
            getOpObj(option)?.label || getOpObj(option)?.name || ""
          }
          renderOption={(listItemProps, option, { selected }) => (
            <li
              {...listItemProps}
              key={option.id}
              className={listItemProps.className + " group"}
            >
              {props.multiple && (
                <Checkbox
                  edge="start"
                  tw="-my-2"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              )}
              <Typography noWrap>{option.name}</Typography>
              {/* {getOptionSecondaryText && (
            <Typography tw="text-neutral-400 text-sm ml-auto group-hover:text-neutral-700">
              {typeof getOptionSecondaryText === "string"
                ? _.get(option, getOptionSecondaryText)
                : getOptionSecondaryText(option)}
            </Typography>
          )} */}
            </li>
          )}
          renderInput={(params) => (
            <StyledTextInput
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
          {...field}
          {...props}
          // overrides
          onChange={(_, data) => {
            const newValue = (Array.isArray(data)
              ? data
              : data?.id || "") as unknown as Multiple extends true
              ? InputOption[]
              : string;
            field.onChange(newValue);
            onChange?.(newValue);
          }}
          value={field.value || null}
        />
      )}
    />
  );
};

export const ControlledCheckboxInput = ({
  control,
  name,
  label,
  ariaLabel,
  labelProps,
  ...props
}: {
  control: Control<any>;
  name: string;
  label?: ReactNode;
  ariaLabel?: string;
  labelProps?: Record<string, any>;
  [key: string]: any;
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <FormControlLabel
        label={label}
        {...labelProps}
        control={
          <Checkbox
            checked={!!field.value}
            inputProps={{ "aria-label": ariaLabel }}
            {...field}
            {...props}
          />
        }
      />
    )}
  />
);
export const ControlledSwitchInput = ({
  control,
  name,
  label,
  labelProps,
  ...props
}: {
  control: Control<any>;
  name: string;
  label?: string;
  labelProps?: Record<string, any>;
  [key: string]: any;
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, ...field } }) => (
      <FormControlLabel
        label={label}
        {...labelProps}
        control={
          <Switch
            checked={!!field.value}
            inputProps={{ "aria-label": label }}
            onChange={() => onChange(!field.value)}
            {...field}
            {...props}
          />
        }
      />
    )}
  />
);

type ControlledTextInputType = Omit<ControllerProps<any>, "render"> &
  Omit<TextInputProps, "ref"> & {
    rules?: ControllerProps<any>["rules"] & {
      transform?: (value: string) => string;
      transformOnBlur?: (value: string) => string;
    };
  };
export const ControlledTextInput = ({
  control,
  name,
  rules,
  onBlur,
  onChange,
  helperText,
  ...props
}: ControlledTextInputType) => {
  const { pattern, required, transform, transformOnBlur } = rules || {};
  return (
    <Controller
      control={control}
      name={name}
      rules={{ pattern, required }}
      render={({ field, fieldState: { error } }) => (
        <StyledTextInput
          {...field}
          {...props}
          value={field.value ?? ""}
          onChange={(e) => {
            const te = transform ? transformEventValue(e, transform) : e;
            field.onChange(te);
            onChange && onChange(te);
          }}
          onBlur={(e) => {
            const te = transformOnBlur
              ? transformEventValue(e, transformOnBlur)
              : e;
            transformOnBlur && field.onChange(te);
            onBlur && onBlur(te);
            field.onBlur();
          }}
          error={!!error}
          helperText={error?.message ?? helperText}
        />
      )}
    />
  );
};

type ControlledDateInputProps = Omit<ControllerProps<any>, "render"> &
  DatePickerProps<Date>;

export const ControlledDateInput = ({
  name,
  control,
  onChange,
  rules,
  slotProps,
  ...props
}: ControlledDateInputProps) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { ref: _, ...field }, fieldState: { error } }) => {
      // running into some weird type issues with slotProps so cast it to any
      const anySlotProps: any = slotProps || {};
      return (
        <DatePicker
          minDate={utcDate("2016-01-01")}
          maxDate={utcDate("2031-01-01")}
          slotProps={{
            ...anySlotProps,
            popper: anySlotProps?.popper,
            field: {
              size: "small",
              ...anySlotProps?.field,
              onBlur: () => {
                anySlotProps?.field?.onBlur?.(field.value);
                // onBlur?.(field.value);
              },
              error: !!error || anySlotProps?.field?.error,
              helperText: error?.message || anySlotProps?.field?.helperText,
            },
          }}
          slots={{ textField: StyledTextInput as any }}
          value={(field.value || null) && utcDate(field.value)}
          onAccept={(date) =>
            date && field.onChange(format(date, "yyyy-MM-dd"))
          }
          onChange={(value: Date | null, context) => {
            let date = value;
            if (
              value &&
              value.getFullYear() > 20 &&
              value.getFullYear() < 100
            ) {
              date = addYears(value, 2000);
            }
            if (!context.validationError && date) {
              onChange?.(value, context);
              field.onChange(format(date, "yyyy-MM-dd"));
            }
            if (!date) {
              field.onChange(null);
              onChange?.(null, context);
            }
          }}
          {...props}
        />
      );
    }}
  />
);

export const ControlledRadioGroup = ({
  name,
  control,
  rules,
  label,
  children,
  className,
  ...props
}: {
  name: string;
  control: Control;
  label?: string;
  className?: string;
  helperText?: string;
  rules?: Record<string, any>;
  children: ReactElement | ReactElement[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <FormControl className={className}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup {...field} {...props} value={field.value ?? ""}>
          {children}
        </RadioGroup>
        {error && (
          <FormHelperText error={true}>{error?.message}</FormHelperText>
        )}
      </FormControl>
    )}
  />
);
