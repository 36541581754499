/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "@mui/material";

import { FaIcon, Title } from "@features/ui";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useCurrentOrderSet } from "./CurrentOrderSetContext";
import useCanEditOrderSet from "./useCanEditOrderSet";

const OrderSetNavigation = ({
  stage,
}: {
  stage?: "overview" | "checkout" | "approval";
}) => {
  const { orderSet } = useCurrentOrderSet();

  const usesApprovalFlow = useSelector(
    (state: any) => state.currentUser.organization.usesApprovalFlow
  );
  const canEdit = useCanEditOrderSet();
  const isApprover = useRoleIs()(permissions.orderReview) && usesApprovalFlow;

  if (canEdit && orderSet.status !== "draft") {
    stage = "approval";
  }

  const addItemsUrl =
    orderSet.type === "pre-order"
      ? `/programs/${orderSet.program?.id}/order/${orderSet.orderWindow?.id}`
      : `/order-sets/${orderSet.type}`;

  const backButtonUrl = stage
    ? {
        overview: "../",
        checkout: "../overview",
        approval: "/orders/approval",
      }[stage]
    : addItemsUrl;

  const title = {
    "on-demand": `On-Demand Order-Set #${orderSet.id}`,
    "pre-order": `Pre-Order #${orderSet.id}`,
    inventory: `Inventory Order-Set #${orderSet.id}`,
  }[orderSet.type];

  return (
    <div tw="flex justify-between items-center">
      <DocTitle title={title} />
      <Title
        backUrl={backButtonUrl}
        override={
          stage === "overview" && !canEdit
            ? isApprover
              ? "/orders/approval"
              : "/orders/history"
            : undefined
        }
      >
        {title}
      </Title>
      <div tw="flex items-center gap-2">
        {orderSet.status === "draft" && (
          <Button
            component={Link}
            variant="contained"
            color="secondary"
            to={addItemsUrl}
            startIcon={<FaIcon icon="plus" />}
            size="small"
            tw="whitespace-nowrap"
          >
            Add Items
          </Button>
        )}
        {stage && canEdit && (
          <Button
            component={Link}
            to="../"
            tw="whitespace-nowrap"
            variant="contained"
            color="secondary"
            startIcon={<FaIcon icon="pen" tw="text-sm!" />}
            size="small"
          >
            Edit Order
          </Button>
        )}
      </div>
    </div>
  );
};

export default OrderSetNavigation;
