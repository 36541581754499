/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { useCreateItemMutation } from "@features/items";

import { StyledButton } from "../../../components/StyledComponents";
import MinimalItemCard from "../../items/components/MinimalItemCard";
import BlockCard from "../../ui/BlockCard";
import useProgramItems from "../useProgramItems";

const Items = ({
  saveItems,
  numberOfItems,
}: {
  saveItems: (ids: string[]) => Promise<any>;
  numberOfItems: number;
}) => {
  const navigate = useNavigate();
  const createItemMutation = useCreateItemMutation();
  const formItems = useWatch({ name: "items" });
  const territories = useWatch({ name: "territories" });
  const channelId = useWatch({ name: "channelId" });
  const programId = useWatch({ name: "id" });
  const [newItemLoading, setNewItemLoading] = useState(false);

  const { data: items, isLoading, refetch } = useProgramItems(programId);

  const handleCreateNewItem = () => {
    const itemIds = items.map((item) => item.id);
    setNewItemLoading(true);
    createItemMutation.mutate(
      {
        channelId,
        territories,
        programId,
      },
      {
        onSuccess: async (newItem) => {
          await saveItems([...itemIds, newItem.id]);
          navigate(`/admin/items/${newItem.id}`);
        },
        onSettled: () => setNewItemLoading(false),
      }
    );
  };

  useEffect(() => {
    refetch();
  }, [formItems, refetch]);
  return (
    <BlockCard
      title="Items"
      titleElement={
        <div tw="flex gap-4 items-baseline">
          {numberOfItems > 0 && (
            <div tw="text-neutral-500">{`${numberOfItems} item${
              numberOfItems > 1 ? "s" : ""
            }`}</div>
          )}
          <StyledButton
            outlined
            onClick={handleCreateNewItem}
            loading={newItemLoading}
          >
            Create New Item
          </StyledButton>
          <StyledButton cta to={"./items"}>
            Assign Items
          </StyledButton>
        </div>
      }
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div
            tw="
            -mx-6 px-6 mb-2
            grid [grid-template-columns: repeat(auto-fill, minmax(10em, 1fr))] 
            gap-4 lg:gap-6 transition-opacity max-h-[340px] overflow-y-auto"
          >
            {items.map((item) => (
              <MinimalItemCard tw="flex-col" item={item} key={item.id} />
            ))}
          </div>
          {items.length === 0 && (
            <div tw="text-lg text-neutral-500 max-w-sm">
              Assign items to this program to make them available to order.
            </div>
          )}
        </>
      )}
    </BlockCard>
  );
};

export default Items;
