import colors from "tailwindcss/colors";

/*
Global styles for the site, handled by Material UI
*/
const theme = {
  palette: {
    primary: {
      light: colors.slate[300],
      main: colors.slate[700],
      dark: colors.slate[900],
      contrastText: colors.slate[100],
    },
    secondary: {
      mute: "#E5F7FF",
      lighter: "#B9EBFF",
      light: "#60C5EA",
      main: "#0099cc",
      dark: "#005685",
      darker: "#003351",
      contrastText: colors.slate[700],
    },
    text: {
      primary: colors.slate[700],
      secondary: colors.slate[500],
    },
    grey: {
      ...colors.slate,
      A100: colors.slate[100],
      A200: colors.slate[200],
      A400: colors.slate[400],
      A700: colors.slate[700],
    },
    action: {
      hover: colors.slate[100],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        endIcon: {
          "& > *:nth-of-type(1)": {
            fontSize: "1.25em",
          },
        },
        startIcon: {
          "& > *:nth-of-type(1)": {
            fontSize: "1.25em",
          },
        },
        root: {
          borderRadius: "4px",
          textTransform: "none",
          fontWeight: 500,
          letterSpacing: "0.025em",
          transition: "all 0.2s ease-in-out",
          variants: [
            {
              props: { size: "small" },
              style: {
                fontWeight: 400,
              },
            },
            {
              props: { color: "primary", variant: "contained" },
              style: {
                "&:hover": {
                  boxShadow:
                    "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                },
              },
            },
            {
              props: { color: "primary", variant: "outlined" },
              style: {
                "&:hover": {
                  boxShadow:
                    "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                },
              },
            },
            {
              props: { variant: "contained", color: "primary" },
              style: {
                backgroundImage: `linear-gradient(to right, ${colors.slate[800]}, ${colors.slate[700]})`,
                color: colors.white,
                "&:hover": {
                  backgroundImage: `linear-gradient(to right, ${colors.slate[800]}, ${colors.slate[600]})`,
                },
                "&.Mui-disabled": {
                  backgroundColor: colors.slate[500],
                  color: colors.slate[200],
                  backgroundImage: "none",
                },
              },
            },
            {
              props: { variant: "contained", color: "secondary" },
              style: {
                backgroundColor: colors.white,
                color: colors.slate[700],
                "&:hover": {
                  backgroundColor: colors.slate[200],
                },
              },
            },
            {
              props: { variant: "contained", color: "neutral" },
              style: {
                backgroundColor: colors.slate[300],
                color: colors.slate[700],
                "&:hover": {
                  backgroundColor: colors.slate[200],
                },
              },
            },
            {
              props: { variant: "outlined", color: "primary" },
              style: {
                borderColor: colors.slate[300],
                backgroundColor: colors.transparent,
                "&:hover": {
                  backgroundColor: colors.transparent,
                  borderColor: colors.slate[400],
                },
              },
            },
            {
              props: { variant: "outlined", color: "secondary" },
              style: {
                borderColor: colors.slate[700],
                color: colors.slate[700],
                backgroundColor: colors.white,
                "&:hover": {
                  borderColor: colors.slate[800],
                },
              },
            },
            {
              props: { variant: "text", color: "secondary" },
              style: {
                color: "#003351",
              },
            },
          ],
        },
      },
      variants: [
        // custom variants -> extend ts type in /types/mui.d.ts
        {
          props: { variant: "danger" },
          style: {
            color: colors.gray[600],
            "&:hover": {
              color: colors.red[600],
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          "& > button.MuiButton-root": {
            borderColor: colors.gray[300],
          },
        },
      },
    },
  },
  itemGrid: {
    paperWrapper: {
      backgroundColor: "whitesmoke",
      width: "95%",
      height: "100%",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    itemGridContainer: {
      maxWidth: "2000px",
      paddingBottom: "20px",
    },
    gridPreviewImage: {
      width: "150px",
      height: "150px",
      borderRadius: "10px",
      objectFit: "cover",
      "&:hover": {
        cursor: "pointer",
      },
    },
    singleItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    singleItemWrapper: {
      position: "relative",
      width: "100%",
      height: "auto",
      padding: "0 5px",
    },
    checkbox: {
      position: "absolute",
      top: "0px",
      right: "0px",
      padding: "0px",
    },
  },
  orderTable: {
    headerCell: {
      position: "relative",
      // padding: "0",
      // height: "150px",
      minWidth: "150px",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    borderRight: {
      borderRight: `1px solid ${colors.slate[300]}`,
    },
    borderRightLight: {
      borderRight: `1px solid ${colors.slate[200]}`,
    },
    infoCell: {
      textAlign: "center",
      width: "100%",
      minWidth: "150px",
      fontSize: ".9rem",
    },
    tableControl: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    orderControl: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    tableClosed: {
      zIndex: "-5",
    },
    root: {
      width: "200px !important",
      maxWidth: "200px !important",
      minWidth: "200px !important",
    },
    colRoot: {
      width: "300px !important",
      maxWidth: "300px !important",
      minWidth: "300px !important",
      zIndex: "50",
    },
    noPadCell: {
      width: "200px !important",
      maxWidth: "200px !important",
      minWidth: "200px !important",
      padding: 0,
    },
  },
  dashboard: {
    dashboardGridItem: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dashPaper: {
      width: "100%",
      maxHeight: "150px",
      paddingBottom: "110%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transition: "all .2s ease-in-out",
      "&:hover": {
        transform: "scale(1.01)",
        cursor: "pointer",
      },
    },
    innerPaper: {
      position: "absolute",
      width: "Calc(100% - 50px)",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    popover: {
      pointerEvents: "none",
      margin: "20px 25px 0 0",
    },
    icon: {
      height: "45%",
      width: "auto",
      objectFit: "cover",
    },
    dashAvatar: {
      height: "75px",
      width: "75px",
      fontSize: "2rem",
      fontWeight: 600,
      backgroundColor: "#0099cc",
      color: "#333333",
    },
    divider: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "20px",
      backgroundColor: "#0099cc",
    },
  },
  global: {
    mainWrapper: {
      maxWidth: "2000px",
      paddingTop: "24px",
    },
    dashWrapper: {
      maxWidth: "1600px",
      paddingTop: "24px",
    },
    landingWrapper: {
      maxWidth: "2000px",
      height: "100vh",
      overflowY: "hidden",
      position: "relative",
      paddingTop: "24px",
    },
    titleImage: {
      display: "flex",
      alignItems: "center",
    },
    previewImage: {
      width: "75px",
      height: "75px",
      borderRadius: "10px",
      objectFit: "cover",
    },
    previewImageFloat: {
      width: "75px",
      height: "75px",
      borderRadius: "10px",
      objectFit: "cover",
      "&:hover": {
        cursor: "pointer",
      },
    },
    hoverText: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    titleText: {
      fontWeight: "600",
      fontSize: "1.5rem",
    },
    headerText: {
      fontWeight: "500",
      fontSize: "1.15rem",
    },
    bodyText: {
      fontSize: "1rem",
    },
    tableText: {
      fontSize: ".9rem",
    },
    titleBar: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: "12px",
      alignItems: "center",
    },
    button: {
      fontWeight: "600",
      fontSize: "1rem",
      textAlign: "center",
      color: "#444444",
      "&.MuiButton-contained": {
        backgroundColor: "#0099cc",
      },
      "&:hover": {
        color: "#00ccff",
      },
    },
    relativeContainer: {
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      top: "0",
      right: "5px",
    },
    configButtons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    innerConfigDiv: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "12px",
      flexWrap: "wrap",
    },
    flexColumnCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    settingsMargin: {
      marginBottom: "15px",
    },
    clickableRow: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    clickableCell: {
      "&:hover": {
        backgroundColor: "#00ccff",
      },
    },
    canceledRow: {
      backgroundColor: "#920000",
      "& td": {
        color: "white",
      },
      "&&:hover": {
        backgroundColor: "#920000",
      },
    },
    fullWidthCenterColumn: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    fullWidthSpaceBetween: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    fullWidth: {
      width: "100%",
    },
    centeredModal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      textAlign: "center",
      margin: "15px 0 15px 0",
      padding: "15px",
      boxSizing: "border-box",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
};

export default theme;
