/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "@mui/material";

import _ from "lodash";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import TrackingModal from "@components/Utility/Modals/TrackingModal";
import { OrderStatus } from "@features/orderHistory";
import { checkoutTypeLabel } from "@features/ordering";
import { OrderSet } from "@models";
import { setOrderSetSelection } from "@redux/slices/orders/orderReviewSlice";
import {
  formatMoneyString,
  formatUtcDate,
  upCase,
} from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

const insertIf = (cond, val) => (cond ? [val] : []);

const OrderSetApprovalTable = ({
  query,
  ...tableProps
}: PaginatedResourceProps & {
  rows: OrderSet[];
  query?: string;
}) => {
  const dispatch = useDispatch();

  const { selectedOrderSetIds } = useSelector(
    (state: any) => state.orderReview
  );

  const [trackingId, setTrackingId] = useState<string | null>(null);
  const {
    role,
    organization: { budgetLocation },
  } = useSelector((state: any) => state.currentUser);

  const handleToggle = (id: string) =>
    dispatch(setOrderSetSelection(_.xor(selectedOrderSetIds, [id])));

  const isSelectable = (row: OrderSet) =>
    row.status === "submitted" ||
    (permissions.reviewApprovals.includes(role) &&
      row.status === "approval-review");

  const selectableRows = tableProps.rows.filter(isSelectable);
  const allSelected =
    selectableRows.length > 0 &&
    selectedOrderSetIds.length === selectableRows.length;
  const handleSelectAll = () =>
    dispatch(
      setOrderSetSelection(allSelected ? [] : selectableRows.map((r) => r.id))
    );

  const columns: Column<OrderSet>[] = [
    {
      id: "_checkbox",
      label: (
        <Checkbox
          tw="-m-3"
          size="small"
          checked={allSelected}
          indeterminate={!allSelected && selectedOrderSetIds.length > 0}
          onChange={handleSelectAll}
          disabled={selectableRows.length === 0}
        />
      ),
      render: (_, row) => (
        <Checkbox
          tw="-m-3"
          size="small"
          checked={selectedOrderSetIds.includes(row.id)}
          onChange={() => handleToggle(row.id)}
          disabled={!isSelectable(row)}
        />
      ),
    },
    {
      id: "id",
      label: "Order Set #",
      sort: "id",
      to: (row) => `/order-sets/${row.id}/overview`,
      render: (id) => (
        <span tw="block hover:underline font-semibold">
          <SearchableText text={id} query={query} />
        </span>
      ),
    },
    {
      id: "type",
      label: "Order Type",
      sort: "type",
      render: (type, row) => (
        <>
          {upCase(type ?? "", "-")}
          {row.orderWindowName && (
            <div tw="text-neutral-500 text-xs">{row.orderWindowName}</div>
          )}
        </>
      ),
    },
    {
      id: "user.name",
      label: "User",
      sort: "user-name",
    },
    {
      id: "programName",
      label: "Program",
      sort: "program-name",
    },
    {
      id: "territory.name",
      label: "Territory",
      sort: "territory-name",
    },
    { id: "stateNames", label: "States" },
    ...insertIf(budgetLocation, {
      id: "budgetName",
      label: "Budget",
      sort: "budget-name",
    }),
    {
      id: "checkoutType",
      label: "Checkout Type",
      render: checkoutTypeLabel,
    },
    {
      id: "totalPrice",
      label: "Total Price",
      align: "right",
      render: formatMoneyString,
    },
    {
      id: "submittedAt",
      label: "Order Date",
      sort: "submitted-at",
      render: (date) => (date ? formatUtcDate(date) : ""),
    },
    {
      id: "status",
      label: "Status",
      sort: "status",
      render: (status) => <OrderStatus status={status} />,
    },
  ];

  return (
    <>
      {trackingId && (
        <TrackingModal
          id={trackingId}
          open={true}
          handleClose={() => setTrackingId(null)}
        />
      )}
      <ReactQueryTable {...tableProps} columns={columns} />
    </>
  );
};

export default OrderSetApprovalTable;
